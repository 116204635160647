import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { x } from "@xstyled/emotion";
import nextLtr from "@iconify/icons-ooui/next-ltr";
import nextRtl from "@iconify/icons-ooui/next-rtl";
import editAlt from "@iconify/icons-bxs/edit-alt";
import baselineDelete from "@iconify/icons-ic/baseline-delete";

import Dropdown from "../../../components/elements/Drodown";
import Search from "../../../components/elements/Search";
import { fetchOnboardedClients } from "../../../redux/clients/onboarding/actionCreators";
import { formatCurrency } from "../../dashboard/helpers";
import { OnboardClientTableProps } from "../interfaces";
import { onboardingClientTableColumns } from "./helpers/onboardingClientTableColumns";
import { ClientTableEnums } from "../../../enums";
import { spacing } from "../../../theme";

interface Props {}

const ClientsUI: React.FC<Props> = () => {
  const clients = useSelector((state: any) => state.onboarding);

  const dispatch = useDispatch();

  const clientData: OnboardClientTableProps[] = clients.clients.map(
    (client: any, index: number) => ({
      no: index + 1,
      id: client.id,
      name: client.name,
      branch: client.branch,
      business_name: client.businessName,
      email: client.email,
      phone: client.telephone,
      kra: client.kraPin,
      fee: client.registrationFeePaid
        ? formatCurrency(client.registrationFeePaid)
        : 0,
      id_number: client.idNumber,
      business_number: client.businessNumber,
    })
  );

  const data = useMemo(() => clientData, [clientData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //@ts-ignore
    page,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    previousPage,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    canPreviousPage,
    //@ts-ignore
    pageOptions,
    //@ts-ignore
    state,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    pageCount,
    prepareRow,
    allColumns,
    //@ts-ignore
    setGlobalFilter,
  } = useTable(
    {
      columns: onboardingClientTableColumns,
      data,
      autoResetGlobalFilter: false,
      //@ts-ignore
      autoResetPage: false,
      //@ts-ignore
      initialState: { pageSize: 2 },
    },
    useGlobalFilter,
    usePagination
  );

  // @ts-ignore
  const { pageIndex, globalFilter } = state;

  const fetchClients = useCallback(() => {
    //@ts-expect-error
    dispatch(fetchOnboardedClients());
  }, [dispatch]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <x.div w='100%'>
      <x.div display='flex' alignItems='center' justifyContent='space-between'>
        <x.h3 fontSize='1.3rem' fontWeight='600' m={spacing[2]}>
          Clients
        </x.h3>
        <Link to='/client/new'>
          <x.button
            outline={{ _: "none", focus: "none" }}
            border='1px solid'
            borderColor='transparent'
            bg={{ _: "primary-main", hover: "primary-dark" }}
            color='white'
            px={spacing[4]}
            py={spacing[2]}
            borderRadius
            mr={spacing[4]}
          >
            Onboard Client
          </x.button>
        </Link>
      </x.div>

      <x.div
        bg='white'
        py={spacing[4]}
        px={spacing[4]}
        my={spacing[4]}
        borderRadius={spacing[2]}
      >
        <x.div
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          my={spacing[2]}
        >
          <Search
            placeholder='Search client by name or email or phone'
            filterValue={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <x.div minWidth='11.25rem' py='0' w={{ sm: "auto", xs: "100%" }}>
            <Dropdown
              showSelectedOption={false}
              keepDropdownOpen
              mt={0}
              options={[null, ...allColumns]}
              renderOption={(column) => (
                <x.label key={column?.id} py={spacing[2]} display='flex'>
                  {!column ? (
                    <>Toggle columns</>
                  ) : (
                    <>
                      <x.input
                        mr={spacing[1]}
                        {...column?.getToggleHiddenProps()}
                        type='checkbox'
                      />{" "}
                      {column?.Header}
                    </>
                  )}
                </x.label>
              )}
              defaultOption={null}
              placeholder='Toggle columns'
              optionToString={(option) => option?.Header?.toString() || ""}
            />
          </x.div>
        </x.div>
        <x.div overflowX='auto' w='100%'>
          <x.table
            {...getTableProps()}
            borderCollapse='collapse'
            my={spacing[4]}
            mx={0}
            fontSize='0.9rem'
            w='100%'
            role='table'
            aria-label='Clients table'
          >
            <x.thead w='100%'>
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <x.tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroupIndex}
                  textAlign='left'
                >
                  {headerGroup.headers.map((column) => (
                    <x.th
                      {...column.getHeaderProps()}
                      key={column.id}
                      px={spacing[2]}
                      pb={spacing[3]}
                      whiteSpace='nowrap'
                    >
                      {column.render("Header")}
                    </x.th>
                  ))}
                </x.tr>
              ))}
            </x.thead>
            <x.tbody {...getTableBodyProps} w='100%'>
              {page.length ? (
                page.map((row: any, rowIndex: any) => {
                  prepareRow(row);
                  return (
                    <x.tr
                      {...row.getRowProps()}
                      key={row.id}
                      bg={rowIndex % 2 === 0 ? "gray-100" : "white"}
                    >
                      {row.cells.map((cell: any) => (
                        <x.td
                          {...cell.getCellProps()}
                          key={cell.column.id}
                          textAlign='left'
                          px={spacing[2]}
                          borderTop={rowIndex !== 0 && "1px solid"}
                          borderColor='secondary-200'
                          py={spacing[2]}
                        >
                          {cell.column.Header === ClientTableEnums.ACTIONS ? (
                            <x.div display='flex' alignItems='center'>
                              <Link to={`/client/loanhistory/${cell.value}`}>
                                <x.button
                                  border='1px solid'
                                  h='2rem'
                                  borderColor='primary-main'
                                  mx={spacing[1]}
                                  color='primary-main'
                                  bg='white'
                                  borderRadius={spacing[1]}
                                  px={spacing[2]}
                                  title='Loan history'
                                >
                                  Loans
                                </x.button>
                              </Link>
                              <Link to="/loans/new">
                                <x.button
                                  border='1px solid'
                                  h='2rem'
                                  borderColor='primary-main'
                                  mx={spacing[1]}
                                  color='primary-main'
                                  bg='white'
                                  borderRadius={spacing[1]}
                                  px={spacing[2]}
                                  title='Apply for loan'
                                >
                                  Apply
                                </x.button>
                              </Link>
                              <Link to={`/client/${cell.value}`}>
                                <x.button
                                  border='1px solid'
                                  h='2rem'
                                  borderColor='primary-main'
                                  mx={spacing[1]}
                                  color='white'
                                  bg='primary-main'
                                  borderRadius={spacing[1]}
                                  px={spacing[2]}
                                  title='View'
                                >
                                  View
                                </x.button>
                              </Link>
                              <Link to={`/client/edit/${cell.value}`}>
                                <x.button
                                  bg='white'
                                  border='1px solid'
                                  borderColor='primary-main'
                                  w='2rem'
                                  h='2rem'
                                  mx={spacing[1]}
                                  color='primary-main'
                                  borderRadius={spacing[1]}
                                  pl={spacing[1]}
                                  title='Edit'
                                >
                                  <Icon icon={editAlt} fontSize='1.5rem' />
                                </x.button>
                              </Link>
                              <x.button
                                bg='white'
                                border='1px solid'
                                borderColor='danger-600'
                                w='2rem'
                                h='2rem'
                                mx={spacing[1]}
                                color='danger-600'
                                borderRadius={spacing[1]}
                                pl={spacing[1]}
                                title='Delete'
                              >
                                <Icon icon={baselineDelete} fontSize='1.5rem' />
                              </x.button>
                            </x.div>
                          ) : (
                            <x.p py={spacing[3]} whiteSpace='nowrap'>
                              {cell.render("Cell")}
                            </x.p>
                          )}
                        </x.td>
                      ))}
                    </x.tr>
                  );
                })
              ) : (
                <x.tr>
                  <x.td colSpan={allColumns.length}>
                    <x.div
                      minHeight='6.25rem'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      No available clients to display
                    </x.div>
                  </x.td>
                </x.tr>
              )}
            </x.tbody>
          </x.table>
          <x.div
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            borderTop='1px solid'
            w='full'
            borderColor='secondary-100'
          >
            <x.div py={spacing[2]}>
              <x.button
                my={spacing[1]}
                bg='transparent'
                border='0px solid'
                textAlign='center'
                borderColor='primary-main'
                color='#9CA3AF'
                pr={spacing[3]}
                py={spacing[1]}
                transition='all 0.3s'
                fontSize='14px'
              >
                <x.span>
                  {"Showing page "}
                  {pageIndex + 1}
                  {" of "}
                  {pageOptions.length}{" "}
                </x.span>
              </x.button>
            </x.div>
            <x.div
              display='flex'
              alignContent='center'
              py={spacing[4]}
              transition='all 0.3sec'
            >
              {canPreviousPage ? (
                <>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    pt={spacing[2]}
                    pb={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => gotoPage(0)}
                    display='flex'
                    mx={spacing[1]}
                  >
                    <Icon icon='ooui:double-chevron-end-rtl' />
                  </x.button>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    py={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => previousPage()}
                    mx={spacing[1]}
                  >
                    <Icon icon={nextRtl} />
                  </x.button>
                </>
              ) : null}

              {pageCount < 6 &&
                Array.from(Array(pageCount).keys()).map((x, i) => (
                  <button
                    style={{
                      backgroundColor: pageIndex === i ? "#86198fff" : "",
                      color: pageIndex === i ? "white" : "#9CA3AF",
                      borderRadius: pageIndex === i ? "50%" : "",
                      border: "0px solid #0052cc",
                      padding: "0px 6px",
                      outline: "none",
                      fontSize: "0.8rem",
                      minWidth: "28px",
                      height: "28px",
                      margin: "0 3px",
                    }}
                    key={i}
                    onClick={() => {
                      gotoPage(i);
                    }}
                  >
                    {i + 1}
                  </button>
                ))}

              {canNextPage ? (
                <>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    py={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => nextPage()}
                    mx={spacing[1]}
                  >
                    <Icon icon={nextLtr} />
                  </x.button>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    py={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => gotoPage(pageCount - 1)}
                    display='flex'
                    alignItems='center'
                    mx={spacing[1]}
                  >
                    <Icon icon='ooui:double-chevron-start-rtl' />
                  </x.button>
                </>
              ) : null}
            </x.div>
          </x.div>
        </x.div>
      </x.div>

      {/* <x.div w='100%'>
        {clients.loading ? (
          <Spinner active={true} />
        ) : (
          <Grid container spacing={2}>
            {clientData.map((client: any) => (
              <Grid spacing={2} item xs={12} sm={6} md={3}>
                <x.div
                  w='100%'
                  p={spacing[4]}
                  bg='white'
                  borderRadius
                  zIndex={2999}
                  minH='25rem'
                >
                  <x.div
                    display='flex'
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <x.img
                      src='/images/avatar.png'
                      w='3rem'
                      h='3rem'
                      borderRadius='50%'
                    />
                    <x.div ml={spacing[2]}>
                      <x.h3
                        textTransform='capitalize'
                        fontSize='0.9rem'
                        fontWeight='600'
                        mb={spacing[2]}
                      >
                        {client.name}
                      </x.h3>
                      <x.p
                        textTransform='capitalize'
                        fontSize='0.9rem'
                        color='secondary-600'
                      >
                        {client.business_name}
                      </x.p>
                    </x.div>
                  </x.div>
                  <x.div my={spacing[2]}>
                    <Grid container>
                      <Grid item>
                        <x.div w='100%' pr={spacing[2]}>
                          <x.p fontSize='0.8rem' fontWeight='600'>
                            ID number
                          </x.p>
                          <x.div
                            my={spacing[2]}
                            borderRadius
                            w='100%'
                            bg='secondary-200'
                            fontSize='0.8rem'
                            fontWeight='600'
                            p={spacing[2]}
                          >
                            {client.id_number}
                          </x.div>
                        </x.div>
                      </Grid>
                      <Grid item>
                        <x.div w='100%'>
                          <x.p fontSize='0.8rem' fontWeight='600'>
                            Kra pin
                          </x.p>
                          <x.div
                            my={spacing[2]}
                            borderRadius
                            w='100%'
                            bg='secondary-200'
                            fontSize='0.8rem'
                            fontWeight='600'
                            p={spacing[2]}
                          >
                            {client.kra}
                          </x.div>
                        </x.div>
                      </Grid>
                    </Grid>
                  </x.div>
                  <x.div
                    bg='secondary-200'
                    p={spacing[2]}
                    w='100%'
                    mb={spacing[2]}
                    borderRadius
                  >
                    <x.p
                      color='secondary-600'
                      fontSize='0.8rem'
                      fontWeight='600'
                    >
                      Email
                    </x.p>
                    <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
                      {client.email}
                    </x.p>
                  </x.div>
                  <x.div
                    bg='secondary-200'
                    p={spacing[2]}
                    w='100%'
                    mb={spacing[2]}
                    borderRadius
                  >
                    <x.p
                      color='secondary-600'
                      fontSize='0.8rem'
                      fontWeight='600'
                    >
                      Phone number
                    </x.p>
                    <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
                      {client.phone}
                    </x.p>
                  </x.div>
                  <x.div
                    bg='secondary-200'
                    p={spacing[2]}
                    w='100%'
                    mb={spacing[2]}
                    borderRadius
                  >
                    <x.p
                      color='secondary-600'
                      fontSize='0.8rem'
                      fontWeight='600'
                    >
                      Registration fee
                    </x.p>
                    <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
                      KES: {formatCurrency(client.fee)}
                    </x.p>
                  </x.div>
                  <x.div
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    mt={spacing[4]}
                  >
                    <Link to={`/client/${client.id}`}>
                      <x.button
                        outline={{ _: "none", focus: "none" }}
                        border='1px solid'
                        borderColor='transparent'
                        bg={{ _: "primary-main", hover: "primary-dark" }}
                        color='white'
                        px={spacing[3]}
                        py={spacing[1.5]}
                        borderRadius
                      >
                        View
                      </x.button>
                    </Link>
                    <x.div
                      display='flex'
                      justifyContent='flex-end'
                      alignItems='center'
                    >
                      <Link to={`/client/edit/${client.id}`}>
                        <x.button
                          outline={{ _: "none", focus: "none" }}
                          border='1px solid'
                          borderColor='primary-main'
                          color='primary-main'
                          h='2rem'
                          w='2rem'
                          borderRadius
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Icon
                            style={{
                              fontSize: "1.5rem",
                            }}
                            icon={editIcon}
                          />
                        </x.button>
                      </Link>

                      <x.button
                        outline={{ _: "none", focus: "none" }}
                        border='1px solid'
                        borderColor='primary-main'
                        color='primary-main'
                        h='2rem'
                        w='2rem'
                        borderRadius
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        ml={spacing[2]}
                      >
                        <Icon
                          style={{
                            fontSize: "1.5rem",
                          }}
                          icon={trashIcon}
                        />
                      </x.button>
                    </x.div>
                  </x.div>
                </x.div>
              </Grid>
            ))}
          </Grid>
        )}
      </x.div> */}
    </x.div>
  );
};

export default ClientsUI;
