import { GET } from "../../../../../config";
import { getConfig } from "../../../../../config/AxiosInstance";

export const fecthClientDetail = (id: any) => {
  const client = async () => {
    const config = getConfig();
    try {
      const res = await GET(`/client/${id}`, config);

      const data = await res.data;

      return data;
    } catch (error: any) {
      console.log("error", error.message);
    }
  };

  return {
    client,
  };
};
