import React from "react";

import ClientLoanRequest from "../../../modules/loans/clientloanrequest/elements";

interface Props {}

const ClientLoanRequestPage: React.FC<Props> = () => {
  return (
    <>
      <ClientLoanRequest />
    </>
  );
};

export default ClientLoanRequestPage;
