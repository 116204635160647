import type { Column } from "react-table";

import { ClientLoanRequest } from "../../../../interfaces";

export const clientLoanRequestTableColumns: Column<ClientLoanRequest>[] = [
  {
    Header: "No",
    accessor: "no",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Client name",
    accessor: "name",
  },
  {
    Header: "Client phone",
    accessor: "telephone",
  },
  {
    Header: "Loan amount",
    accessor: "loan_amt",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Processing Fees",
    accessor: "processingfees",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Registration Fees",
    accessor: "reg_fee",
    //@ts-expect-error
    disableGlobalFilter: true,
  },

  {
    Header: "Application Date",
    accessor: "appliation_date",
    //@ts-expect-error
    disableGlobalFilter: true,
  },

  {
    Header: "CSO",
    accessor: "cso",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "id",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
];
