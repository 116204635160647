import { toast } from "react-toastify";
import { GET, POST, PUT } from "../../../config";
import { getConfig } from "../../../config/AxiosInstance";
import { OnboardClientProps } from "../../../modules/clients/interfaces";
import {
  CREATE_ONBOARD_CLIENT,
  ONBOARD_CLIENT_FAILURE,
  ONBOARD_CLIENT_REQUEST,
  ONBOARD_CLIENT_SUCCESS,
} from "./actionTypes";

const onboardClientRequest = () => {
  return {
    type: ONBOARD_CLIENT_REQUEST,
  };
};

const onboardClientSuccess = (clients: any[]) => {
  return {
    type: ONBOARD_CLIENT_SUCCESS,
    payload: clients,
  };
};

const onboardClientError = (error: any) => {
  return {
    type: ONBOARD_CLIENT_FAILURE,
    payload: error,
  };
};

const createOnboardClient = (client: any) => {
  return {
    type: CREATE_ONBOARD_CLIENT,
    payload: client,
  };
};

export const onboardClient = (client: OnboardClientProps, navigate: any) => {
  const config = getConfig();

  return async (dispatch: any) => {
    try {
      const res = await POST(
        "/client",
        {
          name: `${client.fname} ${client.lname}`,
          telephone: client.phone,
          idNumber: client.id_number,
          email: client.email,
          kraPin: client.kra,
          registrationFeePaid: client.amount,
          businessName: client.business_name,
          businessNumber: client.business_number,
          branchId: client.branchId,
        },
        config
      );

      dispatch(createOnboardClient(res.data));
      toast.success("Client has been onboarded successfully");
      navigate("/client");
    } catch (error) {}
  };
};

export const editClient = (
  client: OnboardClientProps,
  navigate: any,
  id: number | string
) => {
  const config = getConfig();

  return async (dispatch: any) => {
    try {
      const res = await PUT(
        `/client/${id}`,
        {
          id,
          name: `${client.fname} ${client.lname}`,
          telephone: client.phone,
          idNumber: client.id_number,
          email: client.email,
          kraPin: client.kra,
          registrationFeePaid: client.amount,
          businessName: client.business_name,
          businessNumber: client.business_number,
          branchId: client.branchId,
        },
        config
      );

      dispatch(createOnboardClient(res.data));
      toast.success("Client has been updated successfully");
      navigate("/client");
    } catch (error) {}
  };
};

export const fetchOnboardedClients = () => {
  const config = getConfig();

  return async (dispatch: any) => {
    dispatch(onboardClientRequest());

    try {
      const res = await GET("/client", config);

      const data = await res.data;

      dispatch(onboardClientSuccess(data));
    } catch (error: any) {
      console.log("onboard error", error);
      onboardClientError(error.message);
    }
  };
};
