import {
  ONBOARD_CLIENT_FAILURE,
  ONBOARD_CLIENT_REQUEST,
  ONBOARD_CLIENT_SUCCESS,
} from "./actionTypes";

interface InitialStateProps {
  loading: boolean;
  clients: any[];
  error: string;
}

const initialState: InitialStateProps = {
  loading: false,
  clients: [],
  error: "",
};

export const onboardingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ONBOARD_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ONBOARD_CLIENT_SUCCESS:
      return {
        loading: false,
        clients: action.payload,
        error: "",
      };

    case ONBOARD_CLIENT_FAILURE:
      return {
        loading: false,
        clients: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
