import React, { useState, useEffect, useCallback } from "react";
import styled, { x } from "@xstyled/emotion";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import userFilled from "@iconify/icons-carbon/user-filled";
import phoneFill from "@iconify/icons-bi/phone-fill";
import mailIcon from "@iconify/icons-material-symbols/mail";
import idSolid from "@iconify/icons-teenyicons/id-solid";
import pinIcon from "@iconify/icons-material-symbols/pin";
import outlineBusiness from "@iconify/icons-ic/outline-business";
import businessTime from "@iconify/icons-la/business-time";
import moneyLine from "@iconify/icons-majesticons/money-line";

import Grid from "../../../../components/elements/Grid";
import Dropdown from "../../../../components/elements/Drodown";
import { fetchBranches } from "../../../../redux/branches/actionCreators";
import { fecthClientDetail } from "../clientdetail/hooks";
import { spacing } from "../../../../theme";

import { BranchProps, OnboardClientProps } from "../../interfaces";
import { editClient } from "../../../../redux/clients/onboarding/actionCreators";

interface Props {
  id: any;
}

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const EditClientDetailPage: React.FC<Props> = ({ id }) => {
  const [formData, setFormData] = useState<OnboardClientProps>({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    branchId: "",
    amount: undefined,
    business_name: "",
    business_number: "",
    id_number: "",
    kra: "",
  });

  const [defaultBranch, setDefaultBranch] = useState<BranchProps>({
    id: 1,
    name: "",
  });

  const branches = useSelector((state: any) => state.branches);

  const { client: clientData } = fecthClientDetail(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const branchData = branches.branches.map((branch: any) => ({
    id: branch.id,
    name: branch.name,
  }));

  const fetchClient = useCallback(() => {
    clientData()
      .then((user) => {
        const {
          branchId,
          telephone: phone,
          email,
          kraPin: kra,
          name,
          registrationFeePaid: amount,
          idNumber: id_number,
          businessName: business_name,
          businessNumber: business_number,
        } = user;

        const nameParts = name.split(" ");
        const fname = nameParts[0];
        const lname = nameParts.slice(1).join(" ");

        const formattedClientData: OnboardClientProps = {
          fname,
          lname,
          email,
          phone,
          kra,
          amount,
          id_number,
          business_name,
          business_number,
          branchId,
        };

        setFormData(formattedClientData);

        const default_branch = branchData.find(
          (branch: any) => branch.id === branchId
        );

        if (default_branch) {
          setDefaultBranch(default_branch);
        }
      })
      .catch((error) => {
        console.error("Error fetching client:", error);
      });
  }, [branchData, clientData]);

  useEffect(() => {
    fetchClient();
  }, []);

  const handleChange = (data: Partial<OnboardClientProps>) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const handleSelectBranch = (option: BranchProps) => {
    handleChange({ branchId: option ? option.id : 1 });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //@ts-expect-error
    await dispatch(editClient(formData, navigate, id));
  };

  const fetchData = useCallback(() => {
    //@ts-expect-error
    dispatch(fetchBranches());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <x.form
      onSubmit={handleSubmit}
      p={spacing[2]}
      w='100%'
      background='white'
      borderRadius
      minH='60vh'
    >
      <x.div display='flex' alignItems='center' justifyContent='space-between'>
        <x.h3 fontSize='1.3rem' fontWeight='600' m={spacing[2]}>
          Edit Client Details
        </x.h3>
        <Link to='/client'>
          <x.button
            outline={{ _: "none", focus: "none" }}
            border='1px solid'
            borderColor='transparent'
            bg={{ _: "primary-main", hover: "primary-dark" }}
            color='white'
            px={spacing[4]}
            py={spacing[2]}
            borderRadius
            mr={spacing[4]}
          >
            Back
          </x.button>
        </Link>
      </x.div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              First Name
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={userFilled}
                />
              </x.div>
              <x.input
                placeholder='Enter first name'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='fname'
                onChange={(e) => handleChange({ fname: e.target.value })}
                value={formData.fname}
              />
            </x.div>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Last Name
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={userFilled}
                />
              </x.div>
              <x.input
                placeholder='Enter last name'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='lname'
                onChange={(e) => handleChange({ lname: e.target.value })}
                value={formData.lname}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Email
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={mailIcon}
                />
              </x.div>
              <x.input
                placeholder='Enter email'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                type='email'
                name='email'
                onChange={(e) => handleChange({ email: e.target.value })}
                value={formData.email}
              />
            </x.div>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Phone number
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={phoneFill}
                />
              </x.div>
              <x.input
                placeholder='Enter phone number'
                type='tel'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='phone'
                onChange={(e) => handleChange({ phone: e.target.value })}
                value={formData.phone}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              ID number
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={idSolid}
                />
              </x.div>
              <x.input
                placeholder='Enter id number'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                type='text'
                name='id_number'
                onChange={(e) => handleChange({ id_number: e.target.value })}
                value={formData.id_number}
              />
            </x.div>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Kra Pin
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={pinIcon}
                />
              </x.div>
              <x.input
                placeholder='Enter kra pin'
                type='text'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='kra'
                onChange={(e) => handleChange({ kra: e.target.value })}
                value={formData.kra}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Business name
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={outlineBusiness}
                />
              </x.div>
              <x.input
                placeholder='Enter business name'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                type='text'
                name='business_name'
                onChange={(e) =>
                  handleChange({ business_name: e.target.value })
                }
                value={formData.business_name}
              />
            </x.div>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Business number
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={businessTime}
                />
              </x.div>
              <x.input
                placeholder='Enter business number'
                type='tel'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='business_number'
                onChange={(e) =>
                  handleChange({ business_number: e.target.value })
                }
                value={formData.business_number}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Branch
            </x.label>

            <x.div mt={-1} mr={{ _: 0, md: spacing[2] }}>
              <Dropdown<BranchProps>
                placeholder='Select Branch'
                options={branchData}
                renderOption={(option) => (
                  <x.p py={1} mt={1} w='100%'>
                    {option?.name}
                  </x.p>
                )}
                optionToString={(option) => option?.name || ""}
                isActiveOption={(option, selected) => {
                  if (option && selected) {
                    return option.id === selected.id;
                  }
                  return false;
                }}
                getSelectedOption={handleSelectBranch}
                defaultOption={defaultBranch}
                defaultValue={defaultBranch?.id}
              />
            </x.div>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Amount
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={moneyLine}
                />
              </x.div>
              <x.input
                placeholder='Enter amount'
                type='number'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='amount'
                onChange={(e) =>
                  handleChange({ amount: Number(e.target.value) })
                }
                value={formData.amount}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <x.div my={spacing[4]} mx={spacing[2]}>
        <x.button
          outline={{ _: "none", focus: "none" }}
          border='1px solid'
          borderColor='transparent'
          bg={{ _: "primary-main", hover: "primary-dark" }}
          color='white'
          px={spacing[6]}
          py={spacing[2]}
          borderRadius
          type='submit'
        >
          Submit
        </x.button>
      </x.div>
    </x.form>
  );
};

export default EditClientDetailPage;
