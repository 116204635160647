import {
  CREATE_LOAN_REQUEST,
  FETCH_LOAN_DISBURSEMENTS,
  FETCH_LOAN_DISBURSEMENTS_ERROR,
  FETCH_LOAN_DISBURSEMENTS_REQUEST,
  FETCH_LOAN_ERROR,
  FETCH_LOAN_REQUEST,
  FETCH_LOAN_SUCCESS,
  FETCH_LOAN_TYPES_ERROR,
  FETCH_LOAN_TYPES_REQUEST,
  FETCH_LOAN_TYPES_SUCCESS,
} from "./actionType";

import type { ClientLoanRequest, LoanTypes } from "../../interfaces";

interface IState {
  loading: boolean;
  loans: LoanTypes[];
  error: any;
}

interface IClientLoan {
  loading: boolean;
  loansrequests: ClientLoanRequest[];
  error: any;
}

const initialState: IState = {
  loading: false,
  loans: [],
  error: "",
};

const initialLoanState: IClientLoan = {
  error: "",
  loading: false,
  loansrequests: [],
};

const initialLoanDisbursement = {
  error: "",
  loading: false,
  loans: [],
};

export const loansReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_LOAN_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOAN_TYPES_SUCCESS:
      return {
        loading: false,
        loans: action.payload,
        error: "",
      };
    case FETCH_LOAN_TYPES_ERROR:
      return {
        loading: false,
        loans: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const clientLoanRequestReducer = (
  state = initialLoanState,
  action: any
) => {
  switch (action.type) {
    case FETCH_LOAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOAN_SUCCESS:
      return {
        loading: false,
        loansrequests: action.payload,
        error: "",
      };
    case CREATE_LOAN_REQUEST:
      return {
        loading: false,
        loansrequests: [action.payload, ...state.loansrequests],
        error: "",
      };
    case FETCH_LOAN_ERROR:
      return {
        loansrequests: false,
        loans: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const loanDisbursmentReducer = (
  state = initialLoanDisbursement,
  action: any
) => {
  switch (action.type) {
    case FETCH_LOAN_DISBURSEMENTS_REQUEST:
      return {
        ...state,
        laoding: true,
      };
    case FETCH_LOAN_DISBURSEMENTS:
      return {
        loading: false,
        loans: action.payload,
        error: "",
      };
    case FETCH_LOAN_DISBURSEMENTS_ERROR:
      return {
        loading: false,
        loans: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
