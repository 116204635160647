import {
  FETCH_CLIENT_ERROR,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
} from "./actionTypes";

interface InitialStateProps {
  loading: boolean;
  clients: any[];
  error: string;
}

const initialState: InitialStateProps = {
  loading: false,
  clients: [],
  error: "",
};

export const clientReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLIENT_SUCCESS:
      return {
        loading: false,
        clients: action.payload,
        error: "",
      };
    case FETCH_CLIENT_ERROR:
      return {
        loading: false,
        clients: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
