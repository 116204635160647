import { GET } from "../../../config";
import { getConfig } from "../../../config/AxiosInstance";
import {
  FETCH_CLIENT_LOAN_HISTORY_ERROR,
  FETCH_CLIENT_LOAN_HISTORY_REQUEST,
  FETCH_CLIENT_LOAN_HISTORY_SUCCESS,
} from "./actionTypes";

const fetchClientLoanHistoryRequest = () => {
  return {
    type: FETCH_CLIENT_LOAN_HISTORY_REQUEST,
  };
};

const fetchClientLoanHistorySuccess = (clients: any) => {
  return {
    type: FETCH_CLIENT_LOAN_HISTORY_SUCCESS,
    payload: clients,
  };
};

const fetchClientLoanHistoryError = (error: any) => {
  return {
    type: FETCH_CLIENT_LOAN_HISTORY_ERROR,
    payload: error,
  };
};

export const fetchClientLoanHistory = (id: any) => {
  const config = getConfig();
  return async (dispatch: any) => {
    dispatch(fetchClientLoanHistoryRequest());

    try {
      const res = await GET(`/loanrequest/byclient/${id}`, config);

      const data = await res.data;

      dispatch(fetchClientLoanHistorySuccess(data));
    } catch (error: any) {
      console.log("error", error);
      dispatch(fetchClientLoanHistoryError(error));
    }
  };
};
