import { GET } from "../../config";
import { getConfig } from "../../config/AxiosInstance";
import {
  FETCH_CLIENT_ERROR,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
} from "./actionTypes";

export const fetchClientsRequest = () => {
  return {
    type: FETCH_CLIENT_REQUEST,
  };
};

export const fetchClientsSuccess = (clients: any[]) => {
  return {
    type: FETCH_CLIENT_SUCCESS,
    payload: clients,
  };
};

export const fetchClientsError = (error: any) => {
  return {
    type: FETCH_CLIENT_ERROR,
    payload: error,
  };
};

export const fetchClients = (navigate: any) => {
  const config = getConfig();
  return async (dispatch: any) => {
    dispatch(fetchClientsRequest());
    try {
      const res = await GET("/paymentnotifications", config);

      const data = await res.data;

      dispatch(fetchClientsSuccess(data));
    } catch (error: any) {
      dispatch(fetchClientsError(error.message));
      navigate("/login");
    }
  };
};
