import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";

import Layout from "../components/layout/Layout";
import BatchFilesPage from "../pages/batch";
import ClientsPage from "../pages/clients";
import DashboardPage from "../pages/dashboard";
import RepaymentsPage from "../pages/repayments";
import RepaymentDetailPage from "../pages/repayments/view";
import LoginPage from "../pages/login";
import RegisterPage from "../pages/signup";
import ClientOnboardinPage from "../pages/clients/new";
import HomePage from "../pages";
import ClentDetailPage from "../pages/clients/view";
import LoanRequestsPage from "../pages/loans";
import ClientLoanRequestPage from "../pages/loans/clientloanrequest";
import EditClientPage from "../pages/clients/edit";
import PrivateRoutes from "./auth";
import LoanRequestDetailPage from "../pages/loans/clientloanrequest/view";
import EditClientLoanRequestPage from "../pages/loans/clientloanrequest/edit";
import ClientLoanHistoryPage from "../pages/clients/loanhistory";
import NewLoanPage from "../pages/clients";
import LoanDisbursementPage from "../pages/loans/loandisbursements";
import ErrorPage from "./error-page";

// 


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />} errorElement={<ErrorPage />}>
      <Route index element={<HomePage />} />
      <Route path='login' element={<LoginPage />} />
      <Route path='register' element={<RegisterPage />} />
      <Route element={<PrivateRoutes />}>
        <Route path='repayments' element={<RepaymentsPage />}>
          <Route path=':id' element={<RepaymentDetailPage />} />
        </Route>

        <Route path='dashboard' element={<DashboardPage />} />
        <Route path='client'>
          <Route index element={<ClientsPage />} />
          <Route path='new' element={<ClientOnboardinPage />} />
          <Route path=':id' element={<ClentDetailPage />} />
          <Route path='edit/:id' element={<EditClientPage />} />
          <Route path='loanhistory/:id' element={<ClientLoanHistoryPage />} />
          <Route path='newloan/:id' element={<NewLoanPage />} />
        </Route>
        <Route path='loans'>
          <Route index element={<LoanRequestsPage />} />
          <Route path='new' element={<ClientLoanRequestPage />} />
          <Route path='loandisbursements' element={<LoanDisbursementPage />} />
          <Route path=':id' element={<LoanRequestDetailPage />} />
          <Route path='edit/:id' element={<EditClientLoanRequestPage />} />
        </Route>
        <Route path='batchfiles' element={<BatchFilesPage />} />
      </Route>
      {/* default redirect to home page */}
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
    
  )
);
