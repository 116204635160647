import type { Column } from "react-table";

import { ClientLoanHistory } from "../../interfaces";

export const clientLoanHistoryTableColumns: Column<ClientLoanHistory>[] = [
  {
    Header: "No",
    accessor: "no",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Loan amount",
    accessor: "loan_amt",
  },
  {
    Header: "Processing Fees",
    accessor: "processingfees",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
  },

  {
    Header: "Application Date",
    accessor: "appliation_date",
    //@ts-expect-error
    disableGlobalFilter: true,
  },

  {
    Header: "CSO",
    accessor: "cso",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
];
